import React from 'react';
import styled from 'styled-components';
import TenYearsIcon from "images/icon/services/10years.png"
import StructuredProcessIcon from "images/icon/services/structuredProcess.png"
import ReducedTimeAndCostsIcon from "images/icon/services/ReducedTimeCosts.png"
import PredictableIcon from "images/icon/services/predictable.png"
import RisksManagementIcon from "images/icon/services/RisksManagement.png"
import TechGeeksIcon from "images/icon/services/TechGeecks.png"

const FeatureCard = styled.div`
  && {
    height: 100%;
  }
`;

const Feature = ({ icon, title, titleRow2, text, href }) => (
  <FeatureCard className="card shadow text-center overflow-hidden bg-white rounded p-4">
    <div className="icon">
      <img src={icon} alt="Icon" className="avatar" />
    </div>

    <p className="mt-3 card-title text-dark mb-0">
      {title}
      <br />
      {titleRow2}
    </p>
  </FeatureCard>
);

/* style={{
backgroundColor: '#30cfd0',
backgroundImage: 'linear-gradient(180deg, #30cfd0 25%, #ffffff 100%)',
}} */

const WhatWeOffer = () => (
  <section
    className="section-lg bg-light-blue"
    style={{ borderTop: '2px solid white' }}
  >
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
          <Feature title="10+ Years" titleRow2="of experience" icon={TenYearsIcon} />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mt-4">
          <Feature
            title="Predictable &"
            titleRow2="Dedicated"
            icon={PredictableIcon}
          />
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 col-12  mt-4">
          <Feature
            title="Structured"
            titleRow2="Development Process"
            icon={StructuredProcessIcon}
          />
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 col-12  mt-4">
          <Feature title="Risks Management" icon={RisksManagementIcon} />
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 col-12  mt-4">
          <Feature
            title="Reduced"
            titleRow2="Time & Costs"
            icon={ReducedTimeAndCostsIcon}
          />
        </div>
        <div className="col-xl-3 col-lg-4 col-sm-6 col-12  mt-4">
          <Feature title="Tech" titleRow2="Geeks" icon={TechGeeksIcon} />
        </div>
      </div>
    </div>
  </section>
);

export default WhatWeOffer;
