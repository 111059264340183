import React from 'react';
import Layout from 'components/layout';
import { graphql } from 'gatsby';
import Shape from "components/shape";
import { HeroTitle, HeroSubtitle, HeroRequestButton } from 'components/sections/hero';
import Technologies from 'components/sections/servicesPage/technologies';
import WhatWeOffer from 'components/sections/servicesPage/whatweoffer';
import Footer from 'components/footer';
import { DiagonalShape } from 'components/shape';

// 'linear-gradient(180deg,#00345e 22.07%,rgba(39,83,118,.848958) 37.69%,rgba(63,102,134,.751626) 51.62%,rgba(144,167,185,.433474) 68.08%,rgba(255,255,255,0) 86.52%),linear-gradient(180deg,#006686 44.1%,rgba(196,196,196,0) 100%)',
// 'linear-gradient(rgba(0, 52, 94, 0.61) 22.07%, rgba(39, 83, 118, 0.34) 37.69%, rgba(63, 102, 134, 0.753) 51.62%, rgba(144, 167, 185, 0.435) 68.08%, rgba(255, 255, 255, 0) 86.52%), linear-gradient(rgb(0, 102, 134) 44.1%, rgba(196, 196, 196, 0) 100%)',
      {/* backgroundImage: 'linear-gradient(15deg, #80d0c7 0%, #13547a 100%)', */}

const HeroSection = ({ title, text }) => (
  <section
    className="section-xl full-height bg-home overflow-hidden w-100"
    style={{
      backgroundColor: '#ffffff',
      backgroundImage: 'radial-gradient(100% 200% at 50% 150%,#109b9b,#000758)',
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="title-heading text-center mt-4">
            <HeroTitle text={title} />
            <HeroSubtitle text={text} />
            <HeroRequestButton />
          </div>
        </div>
      </div>
    </div>
  </section>
);

const ServicesPage = ({ data }) => (
  <Layout>
    <HeroSection
      title="Full-stack Web Development"
      text="For Companies of All Shapes and Sizes"
    />
    <Shape />
    <Technologies />
    <DiagonalShape color="white" flipped />
    <WhatWeOffer />
    <DiagonalShape color="#043454" />

    <Footer />
  </Layout>
);

export default ServicesPage;