import React from 'react';
import styled from 'styled-components';
import ReactTooltip from "react-tooltip";

import SectionTitle from 'components/sections/title';

import pythonImg from 'images/technologies/python.png';
import rubyImg from 'images/technologies/ruby.png';
import goImg from 'images/technologies/go.png';
import jsImg from 'images/technologies/js.jpeg';
import tsImg from 'images/technologies/ts.jpg';

import djangoImg from 'images/technologies/django.png';
import flaskImg from 'images/technologies/flask.jpg';
import nodeImg from 'images/technologies/nodeJs.png';
import railsImg from 'images/technologies/rails.png';

import reactImg from 'images/technologies/react.png';
import angularImg from 'images/technologies/angular.jpg';
import vueImg from 'images/technologies/vuejs.png';

import nginxImg from 'images/technologies/nginx.png';
import dockerImg from 'images/technologies/docker.png';
import k8sImg from 'images/technologies/k8s.png';
import ansibleImg from 'images/technologies/ansible.png';
import githubImg from 'images/technologies/github.png';
import gitlabImg from 'images/technologies/gitlab.png';
import bitbucketImg from 'images/technologies/bitbucket.jpg';

import awsImg from 'images/technologies/aws.png';
import gcpImg from 'images/technologies/gcp.jpg';
import premImg from 'images/technologies/on-prem.png';
import hubridImg from 'images/technologies/hybrid.png';
import cloudImg from 'images/technologies/in-cloud.png';

import asanaImg from 'images/technologies/asana.png';
import trelloImg from 'images/technologies/trello.jpg';
import jiraImg from 'images/technologies/jira.jpg';
import slackImg from 'images/technologies/slack.jpg';

const ImgIcon = styled.img`
  height: 57px;
  width: 57px;
`;

const LI = styled.li`
  &:last-child {
  }
`;

const TechnologiesItem = ({ title, imgList }) => (
  <div className="row">
    <div className="col-12 text-center mt-3">
      <p className="text-dark card-title">{title}</p>
    </div>
    <div className="col-12">
      <ul className="list-unstyled text-center">
        {imgList.map(item => (
          <LI className="list-inline-item mt-3" key={item.name}>
            <ImgIcon
              src={item.img}
              alt={item.name}
              data-tip={item.name}
              data-place="bottom"
              data-effect="solid"
            />
          </LI>
        ))}
      </ul>
    </div>
  </div>
);

const languages = [
  { img: pythonImg, name: 'Python' },
  { img: rubyImg, name: 'Ruby' },
  { img: goImg, name: 'Go' },
  { img: jsImg, name: 'JavaScript' },
  { img: tsImg, name: 'TypeScript' },
];

const backendFrameworks = [
  { img: djangoImg, name: 'Django' },
  { img: flaskImg, name: 'Flask' },
  { img: nodeImg, name: 'Node.js' },
  { img: railsImg, name: 'Ruby On Rails' },
];

const frontendFrameworks = [
  { img: reactImg, name: 'React.js' },
  { img: angularImg, name: 'Angular' },
  { img: vueImg, name: 'Vue.js' },
];

const devopsTools = [
  { img: nginxImg, name: 'NGINX' },
  { img: dockerImg, name: 'Docker' },
  { img: k8sImg, name: 'Kubernetes' },
  { img: ansibleImg, name: 'Ansible' },
  { img: githubImg, name: 'GitHub' },
  { img: gitlabImg, name: 'GitLab' },
  { img: bitbucketImg, name: 'Bitbucket' },
];

const deploymentPlatforms = [
  { img: awsImg, name: 'Amazon Web Services' },
  { img: gcpImg, name: 'Google Cloud Platform' },
  { img: premImg, name: 'On Premise' },
  { img: hubridImg, name: 'Hybrid' },
  { img: cloudImg, name: 'In Cloud' },
];

const tools = [
  { img: asanaImg, name: 'Asana' },
  { img: trelloImg, name: 'Trello' },
  { img: jiraImg, name: 'Jira' },
  { img: slackImg, name: 'Slack' },
];

const Technologies = () => (
  <section className="section-sm">
    <div className="container">
      <SectionTitle>{'Our Expertise'}</SectionTitle>
      <TechnologiesItem title="Programming Languages" imgList={languages} />
      <TechnologiesItem
        title="Back-end Frameworks"
        imgList={backendFrameworks}
      />
      <TechnologiesItem
        title="Front-end Frameworks"
        imgList={frontendFrameworks}
      />
      <TechnologiesItem title="DevOps" imgList={devopsTools} />
      <TechnologiesItem
        title="Deployment Platforms"
        imgList={deploymentPlatforms}
      />
      <TechnologiesItem
        title="Tools and Services for Management, Documentation & Process Transparency"
        imgList={tools}
      />
    </div>
    <ReactTooltip />
  </section>
);

export default Technologies;
